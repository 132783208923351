import {useCallback, useMemo, useState} from 'react';

/**
 * @typedef SortingSpec
 * @property {string} column The column to sort by
 * @property {'ASC'|'DESC'} direction The direction to sort by
 */

/**
 * @callback ToggleSorting
 * @param {string} column The sorting column to toggle
 * @returns {void}
 */

/**
 * @callback ResetSorting
 * @returns {void}
 */

/**
 * The return value of the useSorting hook
 * @typedef UseSorting
 * @property {SortingSpec|null} sorting The current sorting state
 * @property {ToggleSorting} toggleSorting Toggle the sorting for a given column
 * @property {ResetSorting} resetSorting Reset the sorting to the initial state
 * /

/**
 * The sorting hook to be used with the Table component
 * @param {SortingSpec} initial_sort
 * @returns {UseSorting}
 */
export function useSorting(initial_sort) {
  const [sorting, setSorting] = useState(initial_sort ?? null);

  const toggleSorting = useCallback(
    (column) => {
      setSorting((current_sorting) => {
        const sorting = {
          column,
          direction: current_sorting?.direction === 'ASC' ? 'DESC' : 'ASC',
        };
        if (current_sorting?.column !== column) {
          sorting.direction = 'ASC';
        }
        return sorting;
      });
    },
    [setSorting],
  );

  const resetSorting = useCallback(() => {
    setSorting(initial_sort ?? null);
  }, [initial_sort]);

  const use_sorting = useMemo(
    /** @type {() => UseSorting} */
    () => ({
      sorting,
      toggleSorting,
      resetSorting,
    }),
    [sorting, toggleSorting, resetSorting],
  );

  return use_sorting;
}
